import React, { useState } from "react"

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useSearchParams } from "react-router-dom";

const Navigation = (props) => {
  // search/get params
  const [searchParams, setSearchParams] = useSearchParams();
  const searchFromUrl = searchParams.get("search");
  const [searchPhrase, setSearchPhrase] = useState(searchFromUrl ? searchFromUrl : '');

  const handleSearchChange = (event) => {
    setSearchPhrase(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  const handleSubmitSearch = (event) => {
    let newUrl = `/`;
    if (searchPhrase) {
      newUrl += `?search=${searchPhrase}`;
    }
    window.location = newUrl;
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">Eric London's Pictures</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/?all">view all images</Nav.Link>
          </Nav>

          <Form className="d-flex" onSubmit={handleFormSubmit}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              onChange={handleSearchChange}
              value={searchPhrase}
            />
            <Button variant="outline-success" onClick={handleSubmitSearch}>
              Search
            </Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation
