import React, { useEffect, useState} from "react";
import { Container } from "react-bootstrap";

import { useSearchParams } from "react-router-dom";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Navigation from "./Navigation";
import { API_HOST } from "./constants";

import "./Images.css";

const Images = props => {
  const baseApiImagePath = `${API_HOST}/api/images`;

  // search/get params
  const [searchParams, setSearchParams] = useSearchParams();
  const searchPhrase = searchParams.get("search");
  let allImages = searchParams.get("all") !== null ? true : false;
  if (searchPhrase) allImages = true;

  // api params + state
  const [loadingImages, setLoadingImages] = useState(false);
  let [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [canFetchMore, setCanFetchMore] = useState(true);

  // lighbox state
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightBoxIndex] = useState(0);

  // image containers
  const [photos, setPhotos] = useState([]);
  const [lightboxSlides, setLightboxSlides] = useState([]);

  const processNewImages = (images) => {
    const formattedImages = [];
    const formattedSlides = [];

    images.forEach((image) => {
      // title + description
      let title = image.title;
      if (image.description) {
        title += `\n${image.description}`;
      }

      formattedImages.push({
        key: image.id,
        src: `${API_HOST}${image.thumb_path}`,
        width: 188,
        height: 188,
        title: title,
      });

      formattedSlides.push({
        key: image.id,
        src: `${API_HOST}${image.large_path}`,
        title: title,
      });
    });

    setPhotos(photos.concat(formattedImages));
    setLightboxSlides(lightboxSlides.concat(formattedSlides));
  };

  const fetchImages = () => {
    if (loadingImages) return;
    if (!canFetchMore) return;

    // api URL with get params
    let apiUrl = `${baseApiImagePath}/?page=${currentPage}`;
    if (!allImages) {
      apiUrl += `&album=Artsy`;
    } else if (searchPhrase) {
      apiUrl += `&search=${searchPhrase}`;
    }

    setLoadingImages(true);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((json) => {
        setTotalPages(json.meta.total_pages);
        if (json.images && json.images.length > 0) {
          processNewImages(json.images);
        } else {
          setCanFetchMore(false);
        }
        setLoadingImages(false);
      });
  };

  const handleScroll = (event) => {
    const bottomScrolled = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

    if (bottomScrolled && canFetchMore) {
      currentPage += 1;
      setCurrentPage(currentPage);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchImages();
  }, [allImages, searchPhrase, currentPage]);

  const setPhotoIndex = (index) => {
    setLightBoxIndex(index);
    setLightboxOpen(true);
  };

  const renderImages = () => {
    if (photos.length === 0) return;

    return (
      <div className="mt-3 mb-3">
        <PhotoAlbum
          layout="masonry"
          photos={photos}
          targetRowHeight={188}
          onClick={({ index }) => setPhotoIndex(index)}
        />

        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          slides={lightboxSlides}
          index={lightboxIndex}
          animation={{ fade: 200, swipe: 200 }}
        />
      </div>
    );
  };

  return (
    <div>
      <Navigation />
      <Container>{renderImages()}</Container>
    </div>
  );
}

export default Images
